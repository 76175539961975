<template>
  <va-card title="Patient Debugger - Beta">
    <iframe :src="source"></iframe>
  </va-card>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      source: "/WebGL/index.html",
    };
  },
  created() {},
  methods: {},
  computed: {},
};
</script>

<style>
 iframe {
  height: 70vh;
  width: 100%;
  border: none;
  box-sizing: border-box;
}
</style>
